<template>
  <div class="d-flex justify-content-between header-second" @keydown.esc="closeSearch">
    <div class="navbar-collapse justify-content-between collapse" :class="{ 'show': isMobMenu }">
      <div
        id="close-navigation-modal"
        class="dropdown-tab-content__item__link text-decoration-none cursor-pointer"
        active-class="active-nav-link"
        @click="toggleMenu"
      />
      <div id="closeMenu" />
      <client-only>
        <div v-if="!switchRoute" class="h-100">
          <ul v-click-outside="naviDefault" class="navbar-nav h-100 align-items-center">
            <li
              v-for="(nav, index) of headerNavigation.gold[0].menu"
              :key="index"
              class="nav-item position-relative h-xl-100"
            >
              <template v-if="nav.subMenu">
                <div
                  class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
                  @click="
                  toggleNav(index, true)
                  "
                >
                  {{ $t(nav.title) }}
                </div>
                <div
                  id="dropdown-menu"
                  class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                  :class="{ 'd-none': !showMenue[index], 'show': isNavigations[index] }"
                >
                  <div class="container p-0">
                    <div class="d-flex align-items-start">
                      <ul
                        id="v-pills-tab"
                        class="nav flex-column nav-pills dropdown-tab d-xl-flex m-0 w-100"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <template v-if="nav?.subMenu?.length">
                          <li
                            v-for="(subType, indexType) of nav.subMenu"
                            :key="indexType"
                            class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          >
                            <template>
                              <div
                                :id="`v-pills-home-tab-${indexType}`"
                                active-class="active"
                                :class="{ active: subType.to === `${$route.params.type}/${$route.params.metal}/${$route.params.gender}` }"
                                class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                                @click="changeActiveGender(indexType)"
                              >
                                <template v-if="subType?.submenu?.length">
                                  <span class="text-white" @click="toggleSubNav(indexType, true)">
                                    {{ $t(subType.title) }}
                                  </span>
                                  <div
                                    class="tab-content v-pills-home-tab"
                                    :id="`subtype-${indexType}`"
                                    v-if="subType?.submenu?.length"
                                    :class="{ 'd-none': !showSubmenue[indexType], 'show': isSubNavigations[indexType] }"
                                  >
                                    <div>
                                      <div>
                                        <div
                                          class="tab-pane fade show"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-home-tab"
                                        >
                                          <div class="d-grid dropdown-tab__content">
                                            <ul
                                              class="dropdown-tab-content list-unstyled mb-0 flex-shrink-0"
                                            >
                                              <li
                                                v-for="(subCatalog, indexCatalog) of subType.submenu"
                                                :key="indexCatalog"
                                                class="dropdown-tab-content__item"
                                                @click="onClickSubTitle"
                                              >
                                                <nuxt-link
                                                  :to="
                                          localePath(subCatalog.to, selectedLanguage.code)
                                        "
                                                  tag="a"
                                                  class="dropdown-tab-content__item__link text-decoration-none"
                                                  active-class="active-nav-link"
                                                >
                                                  {{ $t(subCatalog.title) }}
                                                </nuxt-link>
                                              </li>
                                            </ul>
                                          </div>
                                          <div
                                            v-if="mobileMenuList.length || activeType === 'stores'"
                                            class="d-grid dropdown-tab__content d-xl-none d-block"
                                          >
                                            <template v-for="(mobileMenu, mobileMenuIndex) of mobileMenuList">
                                              <h3
                                                :key="`title-${mobileMenuIndex}`"
                                                class="dropdown-tab__item__btn d-xl-none d-block text-uppercase"
                                              >
                                                {{ $t(mobileMenu.title) }}
                                              </h3>
                                              <ul
                                                v-if="mobileMenu && mobileMenu.subMenu"
                                                :key="`sub-menu-${mobileMenuIndex}`"
                                                class="dropdown-tab-content list-unstyled mb-0 flex-shrink-0"
                                              >
                                                <li
                                                  v-for="(mobileSubCatalog, indexMobileCatalog) of mobileMenu.subMenu"
                                                  :key="indexMobileCatalog"
                                                  class="dropdown-tab-content__item"
                                                >
                                                  <nuxt-link
                                                    tag="a"
                                                    :to="{
                                    path: localePath(`${activeType !== 'stores' ? mobileSubCatalog.to + '/' + mobileMenu.to : mobileSubCatalog.to}`)
                                  }"
                                                    class="dropdown-tab-content__item__link text-decoration-none cursor-pointer"
                                                    active-class="active-nav-link"
                                                  >
                                                    {{ $t(mobileSubCatalog.title) }}
                                                  </nuxt-link>
                                                </li>
                                              </ul>
                                              <div :key="`hr-${mobileMenuIndex}`" class="line-block" />
                                            </template>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                                <nuxt-link
                                  tag="a"
                                  :to="{ path: localePath(subType.to, selectedLanguage.code) }"
                                  class="text-white"
                                  v-else
                                >
                                  {{ $t(subType.title) }}
                                </nuxt-link>
                              </div>
                            </template>
                            <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <nuxt-link
                v-else
                class="nav-link p-0 d-flex align-items-center text-uppercase cursor-pointer"
                aria-label="Toggle navigation"
                tag="a"
                :to="{ path: localePath(nav.to, selectedLanguage.code) }"
              >
                {{ $t(nav.title) }}
              </nuxt-link>
            </li>
            <li
              v-click-outside="hideDeskLng"
              class="nav-item position-relative h-xl-100 d-md-none"
            >
              <div
                @click="isLng = !isLng"
                class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
              >
                {{ selectedLanguage.name }}
              </div>
              <div
                id="dropdown-menu"
                class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                v-show="isLng"
              >
                <div class="container p-0">
                  <div class="d-flex align-items-start">
                    <ul
                      class="nav flex-column nav-pills dropdown-tab m-0 w-100"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <template>
                        <li
                          v-for="(lang, idx) in languages"
                          :key="`lang-${idx}`"
                          class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          @click="selectLanguage(lang),hideDeskLng()"
                        >
                          <template>
                            <div
                              :id="`v-pills-home-tab-${idx}`"
                              class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                            >
                              <span class="text-white">
                                {{ lang.name }}
                              </span>
                            </div>
                          </template>
                          <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-click-outside="hideDeskCurrency"
              class="nav-item position-relative h-xl-100 d-md-none"
            >
              <div
                @click="isCurrency = !isCurrency"
                class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
              >
                {{ activeCurrencyGetters.label }}
              </div>
              <div
                id="dropdown-menu"
                class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                v-show="isCurrency"
              >
                <div class="container p-0">
                  <div class="d-flex align-items-start">
                    <ul
                      class="nav flex-column nav-pills dropdown-tab m-0 w-100"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <template>
                        <li
                          v-for="(currency, index) of currencys"
                          :key="index"
                          class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          @click="changeActiveCurrency(currency),hideDeskCurrency()"
                        >
                          <template>
                            <div
                              :id="`v-pills-home-tab-${index}`"
                              class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                            >
                              <span class="text-white">
                                {{ currency.label }}
                              </span>
                            </div>
                          </template>
                          <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="h-100">
          <ul v-click-outside="naviDefault" class="navbar-nav h-100 align-items-center">
            <li
              v-for="(nav, index) of headerNavigation.silver[0].menu"
              :key="index"
              class="nav-item position-relative h-xl-100"
            >
              <template v-if="nav.subMenu">
                <div
                  class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
                  @click="
                  toggleNav(index, true)
                  "
                >
                  {{ $t(nav.title) }}
                </div>
                <div
                  id="dropdown-menu"
                  class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                  :class="{ 'd-none': !showMenue[index], 'show': isNavigations[index] }"
                >
                  <div class="container p-0">
                    <div class="d-flex align-items-start">
                      <ul
                        class="nav flex-column nav-pills dropdown-tab d-sm-none d-xl-flex m-0 w-100"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <template v-if="nav?.subMenu?.length">
                          <li
                            v-for="(subType, indexType) of nav.subMenu"
                            :key="indexType"
                            class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          >
                            <template>
                              <div
                                :id="`v-pills-home-tab-${indexType}`"
                                active-class="active"
                                :class="{ active: subType.to === `${$route.params.type}/${$route.params.metal}/${$route.params.gender}` }"
                                class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                                @click="changeActiveGender(indexType)"
                              >
                                <template v-if="subType?.submenu?.length">
                                  <span class="text-white" @click="toggleSubNav(indexType, true)">
                                    {{ $t(subType.title) }}
                                  </span>
                                  <div
                                    class="tab-content v-pills-home-tab"
                                    :id="`subtype-${indexType}`"
                                    v-if="subType?.submenu?.length"
                                    :class="{ 'd-none': !showSubmenue[indexType], 'show': isSubNavigations[indexType] }"
                                  >
                                    <div>
                                      <div>
                                        <div
                                          class="tab-pane fade show"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-home-tab"
                                        >
                                          <div class="d-grid dropdown-tab__content">
                                            <ul
                                              class="dropdown-tab-content list-unstyled mb-0 flex-shrink-0"
                                            >
                                              <li
                                                v-for="(subCatalog, indexCatalog) of subType.submenu"
                                                :key="indexCatalog"
                                                class="dropdown-tab-content__item"
                                                @click="onClickSubTitle"
                                              >
                                                <nuxt-link
                                                  :to="
                                          localePath(subCatalog.to, selectedLanguage.code)
                                        "
                                                  tag="a"
                                                  class="dropdown-tab-content__item__link text-decoration-none"
                                                  active-class="active-nav-link"
                                                >
                                                  {{ $t(subCatalog.title) }}
                                                </nuxt-link>
                                              </li>
                                            </ul>
                                          </div>
                                          <div
                                            v-if="mobileMenuList.length || activeType === 'stores'"
                                            class="d-grid dropdown-tab__content d-xl-none d-block"
                                          >
                                            <template v-for="(mobileMenu, mobileMenuIndex) of mobileMenuList">
                                              <h3
                                                :key="`title-${mobileMenuIndex}`"
                                                class="dropdown-tab__item__btn d-xl-none d-block text-uppercase"
                                              >
                                                {{ $t(mobileMenu.title) }}
                                              </h3>
                                              <ul
                                                v-if="mobileMenu && mobileMenu.subMenu"
                                                :key="`sub-menu-${mobileMenuIndex}`"
                                                class="dropdown-tab-content list-unstyled mb-0 flex-shrink-0"
                                              >
                                                <li
                                                  v-for="(mobileSubCatalog, indexMobileCatalog) of mobileMenu.subMenu"
                                                  :key="indexMobileCatalog"
                                                  class="dropdown-tab-content__item"
                                                >
                                                  <nuxt-link
                                                    tag="a"
                                                    :to="{
                                    path: localePath(`${activeType !== 'stores' ? mobileSubCatalog.to + '/' + mobileMenu.to : mobileSubCatalog.to}`)
                                  }"
                                                    class="dropdown-tab-content__item__link text-decoration-none cursor-pointer"
                                                    active-class="active-nav-link"
                                                  >
                                                    {{ $t(mobileSubCatalog.title) }}
                                                  </nuxt-link>
                                                </li>
                                              </ul>
                                              <div :key="`hr-${mobileMenuIndex}`" class="line-block" />
                                            </template>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                                <nuxt-link
                                  tag="a"
                                  :to="{ path: localePath(subType.to, selectedLanguage.code) }"
                                  class="text-white"
                                  v-else
                                >
                                  {{ $t(subType.title) }}
                                </nuxt-link>
                              </div>
                            </template>
                            <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <nuxt-link
                v-else
                class="nav-link p-0 d-flex align-items-center text-uppercase cursor-pointer"
                aria-label="Toggle navigation"
                tag="a"
                :to="{ path: localePath(nav.to, selectedLanguage.code) }"
              >
                {{ $t(nav.title) }}
              </nuxt-link>
            </li>
            <li
              v-click-outside="hideDeskLng"
              class="nav-item position-relative h-xl-100 d-md-none"
            >
              <div
                @click="isLng = !isLng"
                class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
              >
                {{ selectedLanguage.name }}
              </div>
              <div
                id="dropdown-menu"
                class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                v-show="isLng"
              >
                <div class="container p-0">
                  <div class="d-flex align-items-start">
                    <ul
                      class="nav flex-column nav-pills dropdown-tab m-0 w-100"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <template>
                        <li
                          v-for="(lang, idx) in languages"
                          :key="`lang-${idx}`"
                          class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          @click="selectLanguage(lang),hideDeskLng()"
                        >
                          <template>
                            <div
                              :id="`v-pills-home-tab-${idx}`"
                              class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                            >
                              <span class="text-white">
                                {{ lang.name }}
                              </span>
                            </div>
                          </template>
                          <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-click-outside="hideDeskCurrency"
              class="nav-item position-relative h-xl-100 d-md-none"
            >
              <div
                @click="isCurrency = !isCurrency"
                class="nav-link p-0 d-flex text-uppercase align-items-center dropdown-toggle cursor-pointer h-xl-100"
              >
                {{ activeCurrencyGetters.label }}
              </div>
              <div
                id="dropdown-menu"
                class="dropdown-menu rounded-0 w-100 m-0 border-0 mega-dropdown"
                v-show="isCurrency"
              >
                <div class="container p-0">
                  <div class="d-flex align-items-start">
                    <ul
                      class="nav flex-column nav-pills dropdown-tab m-0 w-100"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <template>
                        <li
                          v-for="(currency, index) of currencys"
                          :key="index"
                          class="d-flex align-items-center dropdown-tab__item w-100 cursor-pointer position-relative"
                          @click="changeActiveCurrency(currency),hideDeskCurrency()"
                        >
                          <template>
                            <div
                              :id="`v-pills-home-tab-${index}`"
                              class="dropdown-tab__item__btn border-0 btn shadow-none bg-transparent rounded-0 text-start p-0"
                            >
                              <span class="text-white">
                                {{ currency.label }}
                              </span>
                            </div>
                          </template>
                          <div class="dropdown-tab__item__btn__divider d-none d-xl-block" />
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </client-only>
      <div class="d-flex d-xl-none">
        <nuxt-link
          tag="a"
          :to="{ path: localePath('/guaranteed-checking') }"
          class="btn secondary-btn shadow-none rounded-0 d-inline-flex align-items-center justify-content-center text-uppercase text-decoration-none"
        >
          {{ $t('menu.guaranteed_checking') }}
        </nuxt-link>
      </div>
      <ul class="navbar-nav d-xl-none d-flex mobile-settings">
        <li
          class="d-flex justify-content-between align-items-center curency-content"
        >
          <p class="text-uppercase mb-0 mobile-settings__name">
            {{ $t('basic.language') }}
          </p>
          <div class="nav-item dropdown mb-0">
            <a
              class="nav-link p-0 d-flex align-items-center dropdown-toggle text-uppercase"
              href="#"
              role="button"
              @click="showLangMenu = !showLangMenu"
            >
              <img
                v-lazy-load
                :data-src="`/images/${selectedLanguage.icon}`"
                class="flag"
                :alt="selectedLanguage.name"
              >
              {{ selectedLanguage.name }}
            </a>
            <ul class="dropdown-menu mega-dropdown header-dropdown border-0 rounded-0 p-0 m-0 w-100 d-block" :class="{'show': showLangMenu}">
              <li class="dropdown__header d-flex align-items-center position-sticky bg-white">
                <button
                  class="p-0 d-flex align-items-center justify-content-center tab-pane__back btn shadow-none rounded-circle position-relative text-uppercase d-xl-none"
                  @click="showLangMenu = false"
                >
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.226216 3.64645C0.0309543 3.84171 0.0309544 4.15829 0.226216 4.35355L3.4082 7.53553C3.60346 7.7308 3.92004 7.7308 4.1153 7.53553C4.31057 7.34027 4.31057 7.02369 4.1153 6.82843L1.28688 4L4.1153 1.17157C4.31057 0.976311 4.31057 0.659729 4.1153 0.464467C3.92004 0.269204 3.60346 0.269204 3.4082 0.464467L0.226216 3.64645ZM9.42187 3.5L0.57977 3.5L0.57977 4.5L9.42188 4.5L9.42187 3.5Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <p class="m-0 tab-pane__name">
                  {{ $t('basic.language') }}
                </p>
              </li>
              <li
                v-for="(lang, idx) in languages"
                :key="`lang-${idx}`"
                class="d-flex justify-content-between align-items-center header-dropdown__item"
                @click="selectLanguage(lang),closeMenu()"
              >
                <span class="d-flex align-items-center">
                  <img
                    v-lazy-load
                    :data-src="`/images/${lang.icon}`"
                    class="flag"
                    :alt="lang.name"
                  >
                  <span class="text-uppercase">{{ lang.name }}</span>
                </span>

                <svg
                  :class="{
                    'active-checked': lang.code === selectedLanguage.code
                  }"
                  class="header-dropdown__item__icon"
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.36246 8.64325L12.9986 0.982422L14.1741 2.16076L5.36246 10.9999L0.0756454 5.69659L1.25031 4.51826L5.36246 8.64325Z"
                    fill="black"
                  />
                </svg>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="d-flex justify-content-between align-items-center curency-content"
        >
          <p class="text-uppercase mb-0 mobile-settings__name">
            {{ $t('basic.currency') }}
          </p>

          <div class="nav-item dropdown mb-0">
            <a
              class="nav-link p-0 d-flex align-items-center dropdown-toggle text-uppercase"
              href="#"
              role="button"
              @click="showCurrencyMenu = !showCurrencyMenu"
            >
              {{ activeCurrencyGetters.label }}
            </a>
            <ul class="dropdown-menu mega-dropdown header-dropdown border-0 rounded-0 p-0 m-0 w-100" :class="{'show': showCurrencyMenu}">
              <li
                class="d-flex align-items-center position-sticky bg-white dropdown__header"
              >
                <button
                  class="p-0 d-flex align-items-center justify-content-center tab-pane__back btn shadow-none rounded-circle position-relative text-uppercase d-xl-none"
                  @click="showCurrencyMenu = false"
                >
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.226216 3.64645C0.0309543 3.84171 0.0309544 4.15829 0.226216 4.35355L3.4082 7.53553C3.60346 7.7308 3.92004 7.7308 4.1153 7.53553C4.31057 7.34027 4.31057 7.02369 4.1153 6.82843L1.28688 4L4.1153 1.17157C4.31057 0.976311 4.31057 0.659729 4.1153 0.464467C3.92004 0.269204 3.60346 0.269204 3.4082 0.464467L0.226216 3.64645ZM9.42187 3.5L0.57977 3.5L0.57977 4.5L9.42188 4.5L9.42187 3.5Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <p class="m-0 tab-pane__name">
                  {{ $t('basic.currency') }}
                </p>
              </li>

              <li
                v-for="(currency, index) of currencys"
                :key="index"
                class="d-flex justify-content-between align-items-center header-dropdown__item"
                @click="changeActiveCurrency(currency),closeMenu()"
              >
                <span class="d-flex align-items-center">
                  <span class="text-uppercase">
                    {{ currency.label }}
                  </span>
                </span>
                <svg
                  :class="{ 'active-checked': currency.code === activeCurrencyGetters.code }"
                  class="header-dropdown__item__icon"
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.36246 8.64325L12.9986 0.982422L14.1741 2.16076L5.36246 10.9999L0.0756454 5.69659L1.25031 4.51826L5.36246 8.64325Z"
                    fill="black"
                  />
                </svg>
              </li>
            </ul>
          </div>
        </li>
        <ul class="list-unstyled d-flex align-items-center phone">
          <li class="phone__item">
            <a
              href="tel:+37444999750"
              class="text-decoration-none phone__item__link p-10_0"
            >+374 (44) 999-750</a>
          </li>
          <li class="phone__item">
            <a href="viber://chat?number=%2B37444999750" target="_blank" rel="noreferrer">
              <img src="~/assets/images/viber.svg" alt="viber icon" width="22" height="22" data-not-lazy />
            </a>
          </li>
          <li class="phone__item">
            <a
              href="https://api.whatsapp.com/send?phone=37444999750"
              target="_blank"
            >
              <img src="~/assets/images/whatsapp.svg" alt="whatsapp icon" width="22" height="22" data-not-lazy />
            </a>
          </li>
        </ul>
        <nuxt-link :to="{ path: localePath('/aja') }">
          <img
            v-lazy-load
            class="aja-logo-in-menu aja-logo-sizes"
            :data-src="`/images/aja.png`"
          >
        </nuxt-link>
      </ul>
    </div>
    <div class="d-xl-flex w-100 justify-content-xl-end">
      <nuxt-link
        class="services-link pointer-events-none dropdown-toggle btn secondary-btn shadow-none rounded-0 d-xl-inline-flex d-none align-items-center justify-content-center text-uppercase text-decoration-none"
        tag="a"
        :to="{ path: localePath('/') }"
      >
        {{ $t('menu.services') }}
      </nuxt-link>
    </div>
    <transition name="bounce">
      <div
        v-if="scrollPosition > 50"
        class="scroll-to-top"
        @click="scrolToTopAnimation"
      >
        <icon-arrow-small width="12" height="12" fill="white" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { debounce } from '~/core/utils'
import { headerNavigation } from '~/core/utils/header-navigation'
import HeaderMixin from '~/core/mixins/header'
import { GENDERS } from '~/core/utils/enums'

export default {
  name: 'AppHeaderSecond',
  mixins: [HeaderMixin],
  data () {
    return {
      isCurrency: false,
      isLng: false,
      headerNavigation,
      isNavigations: [],
      isSubNavigations: [],
      activeGneder: this.$route.params.gender || 'women',
      activeType: '',
      typeList: [],
      showMenue: [],
      showSubmenue: [],
      showSearchView: false,
      showLangMenu: false,
      showCurrencyMenu: false,
      searchInput: '',
      localHistory: [],
      isFocusSearch: false,
      mobileMenuList: [],
      mobileDevice: false,
      limit: 20,
      page: 0,
      isLoading: false,
      scrollPosition: 0,
      subMenu: {
        0: true
      }
    }
  },
  computed: {
    ...mapState({
      productLists: state => state.product.searchInfo.catalog || [],
      brandLists: state => state.product.searchInfo.brands || [],
      totalCount: state => state.product.searchInfo.total_count,
      maxPrice: state => state.product.searchInfo.max_price || 1550,
      minPrice: state => state.product.searchInfo.min_price || 0,
      isMobMenu: state => state.ui.isMobMenu,
      categoryLists: state => state.categories || []
    }),
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    },
    isSearch () {
      return this.$store.state.ui.isModal === 'search'
    }
  },
  watch: {
    isSearch (val) {
      if (val) {
        this.$store.commit('ui/setModal', 'search')
      } else {
        this.closeSearch()
      }
    },
    searchInput: {
      immediate: false,
      handler (val) {
        this.searchProductHadler()
      }
    },
    activeType: {
      immediate: true,
      handler (type) {
        // eslint-disable-next-line no-unused-vars
        const [_, metal] = type.split('-')
        if (metal === undefined || metal === 'undefined' || metal === 'null' || metal === null) {
          const felem = 'gold'
          if (type === 'stores') {
            this.mobileMenuList = this.headerNavigation.gold.filter(e => e.title === 'menu.stores')
          }
          if (felem?.subMenu) {
            const list = felem.subMenu.catalogsList
            const { WOMEN, MEN, BOY, GIRL } = GENDERS
            const womenList = list.filter(e => e.access.includes(WOMEN))
            const menList = list.filter(e => e.access.includes(MEN))
            const boyList = list.filter(e => e.access.includes(BOY))
            const girlList = list.filter(e => e.access.includes(GIRL))
            const result = [
              {
                title: 'menu.women',
                to: 'women',
                key: 'women-gold',
                subMenu: womenList
              },
              {
                title: 'menu.men',
                to: 'men',
                key: 'men-gold',
                subMenu: menList
              },
              {
                title: 'menu.boy',
                to: 'boy',
                key: 'boy-gold',
                subMenu: boyList
              },
              {
                title: 'menu.girl',
                to: 'girl',
                key: 'girl-gold',
                subMenu: girlList
              }
            ]
            this.mobileMenuList = result
          }
        } else {
          const felem = this.headerNavigation[metal].find(e => e.metal === metal)
          if (type === 'stores') {
            this.mobileMenuList = this.headerNavigation.filter(e => e.title === 'menu.stores')
          }
          if (felem?.subMenu) {
            const list = felem.subMenu.catalogsList
            const { WOMEN, MEN, BOY, GIRL } = GENDERS
            const womenList = list.filter(e => e.access.includes(WOMEN))
            const menList = list.filter(e => e.access.includes(MEN))
            const boyList = list.filter(e => e.access.includes(BOY))
            const girlList = list.filter(e => e.access.includes(GIRL))
            const result = [
              {
                title: 'menu.women',
                to: 'women',
                key: 'women-gold',
                subMenu: womenList
              },
              {
                title: 'menu.men',
                to: 'men',
                key: 'men-gold',
                subMenu: menList
              },
              {
                title: 'menu.boy',
                to: 'boy',
                key: 'boy-gold',
                subMenu: boyList
              },
              {
                title: 'menu.girl',
                to: 'girl',
                key: 'girl-gold',
                subMenu: girlList
              }
            ]
            this.mobileMenuList = result
          }
        }
      }
    },
    $route () {
      this.$store.commit('ui/setModal')
      this.naviDefault()
      if (window.innerWidth < 1199) {
        this.closeMenu()
      }
    }
  },
  mounted () {
    this.naviDefault()
    if (process.browser) {
      if (!localStorage.getItem('searchHistory')) {
        localStorage.setItem('searchHistory', '[]')
      }
      this.closeMenuLissiner = document
        .getElementById('__nuxt')
        .addEventListener('scroll', (e) => {
          this.scrollPosition = e.target.scrollTop
          const closeMenu = document.getElementById('closeMenu')
          closeMenu.click()
        })
      this.resizeLissiner = window.addEventListener('resize', () => {
        if (this.timeOutResize) {
          clearTimeout(this.timeOutResize)
        }
      })
      this.detectBodyClick()
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('resize', this.resizeLissiner)
      window.removeEventListener('scroll', this.closeMenuLissiner)
    }
  },
  created () {
    this.searchProductHadler = debounce(this.searchProductHadler)
    this.blurFocus = debounce(this.blurFocus)
  },
  methods: {
    ...mapActions({
      search: 'product/search'
    }),
    ...mapMutations({
      toggleMenu: 'ui/toggleMenu'
    }),
    hideDeskCurrency () {
      this.isCurrency = false
    },
    hideDeskLng () {
      this.isLng = false
    },
    renderGenderImage (category) {
      switch (this.activeType) {
        case 'men-gold':
          return '/images/gender/men-gold.jpg'

        case 'women-gold':
          return '/images/gender/women-gold.jpg'

        case 'boy-gold':
          return '/images/gender/boy-gold.jpg'

        case 'girl-gold':
          return '/images/gender/girl-gold.jpg'

        case 'men-silver':
          return '/images/gender/men-silver.jpg'

        case 'women-silver':
          return '/images/gender/women-silver.jpg'

        case 'boy-silver':
          return '/images/gender/boy-silver.jpg'

        case 'girl-silver':
          return '/images/gender/girl-silver.jpg'

        default:
          return '/images/gender/women-gold.jpg'
      }
    },
    toggleSeach () {
      if (this.$store.state.ui.isModal !== 'search') {
        this.$store.commit('ui/setModal', 'search')
      } else {
        this.$store.commit('ui/setModal')
      }
    },
    naviDefault () {
      const metal = this.$store.state.siteMode.sitemode
      if (metal === undefined || metal === 'undefined' || metal === 'null' || metal === null) {
        this.isNavigations = this.headerNavigation.gold.map(() => false)
      } else {
        this.isNavigations = (this.headerNavigation[metal] !== undefined) ? this.headerNavigation[metal].map(() => false) : this.headerNavigation.gold.map(() => false)
      }
    },

    toggleNav (i, show) {
      this.showMenue = this.showMenue.map((value, index) => index === i ? value : false)
      this.showMenue[i] = !this.showMenue[i]
      this.naviDefault()
      this.$set(this.isNavigations, i, show)
    },

    toggleSubNav (i, show) {
      this.showSubmenue = this.showSubmenue.map((value, index) => index === i ? value : false)
      this.showSubmenue[i] = !this.showSubmenue[i]
      this.$set(this.isSubNavigations, i, show)
    },

    blurFocus () {
      this.isFocusSearch = false
    },

    loadMore () {
      if (this.showLoadMore() || !this.productLists.length) {
        return
      }
      this.isLoading = true
      this.page += 1
      this.get({ loadMore: true })
    },

    showLoadMore () {
      return this.totalCount === this.productLists?.length
    },
    closeSearch () {
      this.$store.commit('ui/setModal')
    },
    closeMenu () {
      if (this.isMobMenu) {
        this.toggleMenu()
        this.showLangMenu = this.showCurrencyMenu = false
      }
    },

    getCatalogsList ({ catalogsList }, gender, type) {
      this.showMenue = true
      if (type.includes('undefined')) {
        this.typeList = []
        this.activeType = 'stores'
        return
      }
      const felems = catalogsList.filter(e =>
        e.access.includes(this.activeGneder)
      )
      this.typeList = felems
      if (gender && type) {
        this.activeGneder = gender
        this.activeType = type
      }
    },
    changeActiveGender (i) {
      // Create a new object by iterating over the keys of the current subMenu
      const newSubMenu = {}
      for (const key in this.subMenu) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.subMenu.hasOwnProperty(key)) {
          newSubMenu[key] = (Number.isInteger(key) && Number(key) === i) ? this.subMenu[key] : false
        }
      }
      this.subMenu = newSubMenu
      this.subMenu[i] = !this.subMenu[i]
    },
    onClickSubTitle (indexType) {
      this.subMenu.indexType = !this.subMenu.indexType
    },

    resetInput () {
      this.searchInput = ''
      this.$store.commit('product/setSearchInfo', {})
    },
    detectBodyClick () {
      document.body.addEventListener('click', function (event) {
        const showSropdownItem = document.querySelector('.show-dropdown-item')

        if (event.target.closest('.show-dropdown-item') && showSropdownItem) {
          showSropdownItem.classList.toggle('active')
        } else if (
          !event.target.closest('.show-dropdown-item') &&
          showSropdownItem?.classList?.contains('active')
        ) {
          showSropdownItem?.classList?.remove('active')
        }
      })
    },

    searchProductHadler () {
      if (process.browser) {
        const history = JSON.parse(localStorage.getItem('searchHistory'))
        history.push(this.searchInput)
        const locHistory = [...new Set(history)].filter(e => e).slice(0, 50)
        this.localHistory = locHistory.sort((a, b) => a - b)
        localStorage.setItem('  ', JSON.stringify(locHistory))
        this.page = 0
        this.get({ loadMore: false })
      }
    },
    async get ({ loadMore }) {
      const body = {
        query: {
          limit: this.limit,
          offset: this.page * this.limit || 0,
          key: this.searchInput
        },
        loadMore
      }
      await this.search(body)
      this.isLoading = false
    },
    scrolToTopAnimation () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    handleScrollPosition () {
      if (process.browser) {
        return window.pageYOffset
      }
    }
  }
}
</script>

<style scoped lang="css">
.gold .header-second {
  background: linear-gradient(360deg, #EBE0A1 10%, #D8C273 88.63%);
  border: none !important;
}
.silver .header-second {
  background: linear-gradient(360deg, #F2F2F3 10%, #E4E4E4 96.98%);
  border: none !important;
}
.search-fon{
  background: rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  height: 150vh;
  width: 200vw;
  position: fixed;
  opacity: 1;
  left: -50vw;
  top: -200px;
  z-index: 1;
}
.offcanvas.show{
  visibility: visible;
  display: block;
}
.nuxt-link-exact-active.nuxt-link-active{
  pointer-events: none;
}
.dropdown-menu{
  left: 0;
  width: 100%;
  max-width: 200px;
  top: calc(100% + 10px) !important;
  padding: 10px 0;
}
.search-history{
  background: #f1f2f3;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  position: absolute;
  top: 51px;
  height: 100px;
  overflow: auto;
  width: 100%;
  z-index: 999;
}
.search-history div{
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin: 0 20px;
  padding: 5px 0;
}
.aja-logo-in-menu {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.close-search-modal {
  outline: none;
  border: none;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translate(-100%, -50%);
}
.close-search-result {
  position: absolute;
  right: 38px;
}
.line-block {
  height: 1px;
  width: 100%;
  background: #1d3865;
  margin: 16px 0;
  opacity: 0.1;
}

.hide-search-component {
  opacity: 0;
  pointer-events: none;
}

.active-nav-link {
  color: var(--seccondary-color) !important;
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.curency-content {
  padding-bottom: 26px;
}
.search-loading {
  position: fixed;
  z-index: 9999999;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.search-web-block {
  height: 100vh !important;
}
.p-10_0 {
  padding: 10px 0 !important;
}
.scroll-to-top:hover {
  opacity: 0.8;
}
.scroll-to-top {
  transition: all 0.7s;
  cursor: pointer;
  position: fixed;
  bottom: 34px;
  right: 40px;
  background: #1d3865;
  background: var(--seccondary-color);
  border-radius: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  z-index: 99;
}
.scroll-to-top svg {
  transform: rotate(270deg);
}

.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 1199.9px) {
  .scroll-to-top {
    right: 0.75rem;
    bottom: 80px;
  }
  .search-fon{
    top: var(--header-height);
  }
  .search-history{
    margin-top: 0px;
  }
  .header .header-second .nav-item {
    min-width: 184px;
  }
  .header .header-second .nav-item .nav-link {
    justify-content: center;
  }
}
.services-link {
  opacity: 0.7;
}
.services-link:after {
  background: url(/images/dropdown-arrow.svg) no-repeat center !important;
}
.mega-dropdown {
  background: rgba(0, 50, 63, 1);
}
@media(min-width: 1200px) {
  .dropdown-tab__item:hover div.v-pills-home-tab {
    display: block !important;
  }
  .v-pills-home-tab {
    display: none;
    position: absolute;
    left: 100%;
    padding: 13px;
    top: 0;
  }
}
.gold .dropdown-tab__item div.v-pills-home-tab, .gold .dropdown-tab__item:hover {
  background: linear-gradient(100.46deg, #EBE0A1 -3.35%, #D8C273 88.63%);
}
.silver .dropdown-tab__item div.v-pills-home-tab, .silver .dropdown-tab__item:hover {
  background: radial-gradient(195.38% 0% at 132.94% 51.21%, #F2F2F3 0%, #E4E4E4 96.98%);
}
.dropdown-tab__item:hover span {
  color: #000 !important;
}
</style>
